// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@font-face {   
  font-family: 'Roboto';    //This is what we are going to call
  src: url('../assets/Fonts/Roboto-Regular.ttf');
}

@font-face {   
  font-family: 'Comfortaa';    //This is what we are going to call
  src: url('../assets/Fonts/static/Comfortaa-Regular.ttf');
}

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #fe5f55;
  --ion-color-primary-rgb: 255,196,146;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0544b;
  --ion-color-primary-tint: #fe6f66;

  --ion-color-secondary: #7a9e9f;
  --ion-color-secondary-rgb: 61,62,68;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #6b8b8c;
  --ion-color-secondary-tint: #87a8a9;

  --ion-color-tertiary: #b8d8d8;
  --ion-color-tertiary-rgb: 252,108,92;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #a2bebe;
  --ion-color-tertiary-tint: #bfdcdc;

  --ion-color-success: #74a44c;
  --ion-color-success-rgb: 116,164,76;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #669043;
  --ion-color-success-tint: #82ad5e;

  --ion-color-warning: #dba50c;
  --ion-color-warning-rgb: 219, 165, 12;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #c29210;
  --ion-color-warning-tint: #f3b814;

  --ion-color-danger: #aa240e;
  --ion-color-danger-rgb: 170,36,14;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #96200c;
  --ion-color-danger-tint: #b33a26;

  --ion-color-dark: #36373c;
  --ion-color-dark-rgb: 54,55,60;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #303035;
  --ion-color-dark-tint: #4a4b50;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  


  // --ion-background-color	Background color of the entire app
  // --ion-background-color-rgb	Background color of the entire app, rgb format
  // --ion-text-color	Text color of the entire app
  // --ion-text-color-rgb	Text color of the entire app, rgb format
  // --ion-backdrop-color	Color of the Backdrop component
  // --ion-backdrop-opacity	Opacity of the Backdrop component
  // --ion-overlay-background-color	Background color of the overlays
  // --ion-border-color	Border color
  // --ion-box-shadow-color	Box shadow color
  // --ion-tab-bar-background	Background of the Tab Bar
  // --ion-tab-bar-background-focused	Background of the focused Tab Bar
  // --ion-tab-bar-border-color	Border color of the Tab Bar
  // --ion-tab-bar-color	Color of the Tab Bar
  // --ion-tab-bar-color-selected	Color of the selected Tab Button
  // --ion-toolbar-background	Background of the Toolbar
  // --ion-toolbar-border-color	Border color of the Toolbar
  // --ion-toolbar-color	Color of the components in the Toolbar
   --ion-toolbar-segment-color:#7a9e9f;  //	Color of the Segment Buttons in the Toolbar
   --ion-toolbar-segment-color-checked:#ffffff ; //	Color of the checked Segment Buttons in the Toolbar
   --ion-toolbar-segment-background:#ffffff;	//Background of the Segment Buttons in the Toolbar
   --ion-toolbar-segment-background-checked:#7a9e9f;	//Background of the Segment Buttons in the Toolbar
   --ion-toolbar-segment-indicator-color:#ffffff;	 //Color of the Segment Button indicator in the Toolbar
  // --ion-item-background	Background of the Item
  // --ion-item-border-color	Border color of the Item
  // --ion-item-color	Color of the components in the Item
  // --ion-placeholder-color	Color of the placeholder in Inputs

  --ion-background-color: #f4f5f8;
  --ion-background-color-rgb: 244, 245, 248;

  --ion-text-color: #3d3e44;
  --ion-text-color-rgb: 61,62,68;

  --ion-color-step-50: #f5f5f6;
  --ion-color-step-100: #ececec;
  --ion-color-step-150: #e2e2e3;
  --ion-color-step-200: #d8d8da;
  --ion-color-step-250: #cfcfd0;
  --ion-color-step-300: #c5c5c7;
  --ion-color-step-350: #bbbbbe;
  --ion-color-step-400: #b1b2b4;
  --ion-color-step-450: #a8a8ab;
  --ion-color-step-500: #9e9fa2;
  --ion-color-step-550: #949598;
  --ion-color-step-600: #8b8b8f;
  --ion-color-step-650: #818285;
  --ion-color-step-700: #77787c;
  --ion-color-step-750: #6e6e73;
  --ion-color-step-800: #646569;
  --ion-color-step-850: #5a5b60;
  --ion-color-step-900: #505157;
  --ion-color-step-950: #47484d;

}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//      /** primary **/
//   --ion-color-primary: #3d3e44;
//   --ion-color-primary-rgb: 1, 170, 173;
//   --ion-color-primary-contrast: #eeeeee;
//   --ion-color-primary-contrast-rgb: 255, 255, 255;
//   --ion-color-primary-shade: #028285;
//   --ion-color-primary-tint: #05dfe2;

//   /** secondary **/
//   --ion-color-secondary: #eeeeee ;
//   --ion-color-secondary-rgb: 238, 238, 238;
//   --ion-color-secondary-contrast: #3d3e44;
//   --ion-color-secondary-contrast-rgb: 1, 170, 173;
//   --ion-color-secondary-shade: #dadada;
//   --ion-color-secondary-tint: #ffffff ;

//   /** tertiary **/
//   --ion-color-tertiary: #3d3e44;
//   --ion-color-tertiary-rgb: 235, 68, 90;
//   --ion-color-tertiary-contrast: #eeeeee;
//   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//   --ion-color-tertiary-shade: #dd4257;
//   --ion-color-tertiary-tint: #3d3e44;

//   /** success **/
//   --ion-color-success: #2dd36f;
//   --ion-color-success-rgb: 45, 211, 111;
//   --ion-color-success-contrast: #eeeeee;
//   --ion-color-success-contrast-rgb: 255, 255, 255;
//   --ion-color-success-shade: #28ba62;
//   --ion-color-success-tint: #42d77d;

//   /** warning **/
//   --ion-color-warning: #ffc409;
//   --ion-color-warning-rgb: 255, 196, 9;
//   --ion-color-warning-contrast: #000000;
//   --ion-color-warning-contrast-rgb: 0, 0, 0;
//   --ion-color-warning-shade: #e0ac08;
//   --ion-color-warning-tint: #ffca22;

//   /** danger **/
//   --ion-color-danger: #3d3e44;
//   --ion-color-danger-rgb: 235, 68, 90;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255, 255, 255;
//   --ion-color-danger-shade: #cf3c4f;
//   --ion-color-danger-tint: #ed576b;

//   /** dark **/
//   --ion-color-dark: #222428;
//   --ion-color-dark-rgb: 34, 36, 40;
//   --ion-color-dark-contrast: #ffffff;
//   --ion-color-dark-contrast-rgb: 255, 255, 255;
//   --ion-color-dark-shade: #1e2023;
//   --ion-color-dark-tint: #383a3e;

//   /** medium **/
//   --ion-color-medium: #92949c;
//   --ion-color-medium-rgb: 146, 148, 156;
//   --ion-color-medium-contrast: #ffffff;
//   --ion-color-medium-contrast-rgb: 255, 255, 255;
//   --ion-color-medium-shade: #808289;
//   --ion-color-medium-tint: #9d9fa6;

//   /** light **/
//   --ion-color-light: #f4f5f8;
//   --ion-color-light-rgb: 244, 245, 248;
//   --ion-color-light-contrast: #000000;
//   --ion-color-light-contrast-rgb: 0, 0, 0;
//   --ion-color-light-shade: #d7d8da;
//   --ion-color-light-tint: #f5f6f9;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: linear-gradient(90deg,#ffffff 0%,#3d3e44 100%);
//     --ion-background-color-rgb: 238,238,238;
  
//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 0,0,0;
  
//     --ion-color-step-50: #ffffff;
//     --ion-color-step-100: #ffffff;
//     --ion-color-step-150: #ffffff;
//     --ion-color-step-200: #ffffff;
//     --ion-color-step-250: #ffffff;
//     --ion-color-step-300: #ffffff;
//     --ion-color-step-350: #9b9b9b;
//     --ion-color-step-400: #8f8f8f;
//     --ion-color-step-450: #838383;
//     --ion-color-step-500: #777777;
//     --ion-color-step-550: #6b6b6b;
//     --ion-color-step-600: #5f5f5f;
//     --ion-color-step-650: #535353;
//     --ion-color-step-700: #474747;
//     --ion-color-step-750: #3c3c3c;
//     --ion-color-step-800: #303030;
//     --ion-color-step-850: #242424;
//     --ion-color-step-900: #181818;
//     --ion-color-step-950: #0c0c0c;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 238,238,238;
  
//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 0,0,0;
  
//     --ion-color-step-50: #ffffff;
//     --ion-color-step-100: #ffffff;
//     --ion-color-step-150: #ffffff;
//     --ion-color-step-200: #ffffff;
//     --ion-color-step-250: #ffffff;
//     --ion-color-step-300: #ffffff;
//     --ion-color-step-350: #ffffff;
//     --ion-color-step-400: #8f8f8f;
//     --ion-color-step-450: #838383;
//     --ion-color-step-500: #777777;
//     --ion-color-step-550: #6b6b6b;
//     --ion-color-step-600: #5f5f5f;
//     --ion-color-step-650: #535353;
//     --ion-color-step-700: #474747;
//     --ion-color-step-750: #3c3c3c;
//     --ion-color-step-800: #303030;
//     --ion-color-step-850: #242424;
//     --ion-color-step-900: #181818;
//     --ion-color-step-950: #0c0c0c;
//   }
// }
